import axios from './BaseService.js'
const baseURL = process.env.VUE_APP_API_URL + '/report';

export default {
  async findElectricityByDeviceId(deviceId, startDate, endDate, period) {
    var params = {
      startDate: startDate,
      endDate: endDate,
      period: period
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/device/${deviceId}/electricity/general`,{ params });
  },
  async findWaterByDeviceId(deviceId, startDate, endDate, period) {
    var params = {
      startDate: startDate,
      endDate: endDate,
      period: period
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/device/${deviceId}/water/general`,{ params });
  },
};