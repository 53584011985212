<template>
  <n-space
    vertical
  >
    <div class="card">
      <div class="card chart-header-section">
        <div class="card-heading">
          <div class="inline-block">
            <span class="bold text-large">{{ selectedStatsOptions.groupBy === 'day'? 'Daily' :selectedStatsOptions.groupBy.charAt(0).toUpperCase() + selectedStatsOptions.groupBy.slice(1) + 'ly' }} Electricity Consumption</span><br>
            <span class="text-small">
              <span class="bold">Devices: </span> {{ stats.totalDevices }}  
            </span>
          </div>
          <div class="actions-section">
            <n-space justify="end">
              <DropdownList
                v-model:value="selectedStatsOptions.groupBy"
                :placeholder="'Group By'"
                :options="groupBy"
                @valueChanged="handleGroupByChange"
              />
              <n-date-picker
                v-if="renderComponent"
                v-model:value="selectedStatsOptions.selectedDate"
                :actions="selectedStatsOptions.selectedDatePickerType === 'date' ? [] : ['confirm']"
                :is-date-disabled="disablePreviousDate"
                :type="selectedStatsOptions.selectedDatePickerType"
                size="large"
                @update:value="handleDatePickerChange"
              />
            </n-space>
          </div>
        </div>
      </div>
      <n-scrollbar :style="`max-height: ${height}px;`">
        <v-chart
          :option="kwhChartData"
          :update-options="updateOptions"
          autoresize
          style="height: 530px;"
          @click="handleChartClick"
        />
        <v-chart
          :option="kvaChartData"
          :update-options="updateOptions"
          autoresize
          style="height: 530px;"
          @click="handleChartClick"
        />
      </n-scrollbar>
    </div>
  </n-space>
</template>

<script>
    import { ref } from "vue";
    import { use } from "echarts/core";
    import { CanvasRenderer } from "echarts/renderers";
    import { BarChart, LineChart } from "echarts/charts";
    import { TitleComponent, TooltipComponent, LegendComponent, GridComponent } from "echarts/components";
    import VChart, { THEME_KEY } from "vue-echarts";
    import * as dayjs from 'dayjs'
    import DropdownList from '@/components/shared/DropdownList.vue'
    import StatisticalService from '@/services/StatisticalService'

    const currentDate = new Date();
    const chartColors = ['#94B49F', '#7882A4', '#ECA6A6', '#DAB88B', '#E7FBBE', '#D57E7D', '#7B6179', '#A3DDCB', '#F6ABE1', '#5BA46A'];

    use([
        BarChart,
        LineChart,
        CanvasRenderer,
        TitleComponent,
        TooltipComponent,
        LegendComponent,
        GridComponent
    ]);

    export default {
        name: 'TenantElectricityChart',
        components: {
            DropdownList,
            VChart
        },
        props: {
            id: {
                type: Number,
                default: 0,
            },
            height: {
                type: Number,
                default: 540,
            }
        },
        data:() => ({
            isDateAlreadySet: ref(false),
            renderComponent: ref(true),
            selectedStatsOptions: ref({
                range: [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)],
                groupBy: 'month',
                selectedDate: ref(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
                selectedDatePickerType: ref('month')
            }),
            stats: ref({
                totalDevices: 0
            }),
            updateOptions: ref({
                notMerge: true
            }),
            groupBy: ref([{
                label: 'Year',
                value: 'year'
                },
                {
                label: 'Month',
                value: 'month'
                },
                {
                label: "Day",
                value: 'day'
            }]),
            kwhChartData: ref({
                grid: {
                    show: false
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    nameRotate: '90',
                    triggerEvent: true,
                    axisLabel: {
                        interval: 0,
                        rotate: 90,
                        formatter: function (value) {
                            return value;
                        }
                    },
                    axisTick: {
                        alignWithLabel: true
                    },
                },
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: function (value) {
                                 if (value < 1000) {
                                    return value + ' kWh';
                                } else if (value >= 1000 && value <= 1000000) {
                                    return ( value / 1000 ) + ' MWh';
                                } else {
                                    return ( value / 1000000 ) + 'Gwh';
                                }
                            }
                        }
                    },
                ],
                series: []
            }),
            kvaChartData: ref({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'cross'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    nameRotate: '90',
                    triggerEvent: true,
                    axisLabel: {
                        interval: 0,
                        rotate: 90,
                        formatter: function (value) {
                            return value;
                        }
                    },
                    axisTick: {
                        alignWithLabel: true
                    },
                },
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: function (value) {
                                if (value < 1000) {
                                    return value + ' kVA';
                                } else {
                                    return ( value / 1000 ) + ' MVA';
                                }
                            }
                        }
                    }
                ],
                series: [],
                animationEasing: 'elasticOut',
                animationDelayUpdate: function (idx) {
                    return idx * 5;
                }
            }),
        }),
        watch: {
            id: {
                immediate: true,
                handler: function(newVal, oldVal) {
                    if (newVal != 0) {
                        this.findTenantDeviceKwh();
                        this.findTenantDeviceKva();
                    }
                }
            },
            selectedStatsOptions: {
                deep: true,
                handler: function() {
                    this.forceRerender();
                    this.findTenantDeviceKwh();
                    this.findTenantDeviceKva();
                }
            },
        },
        methods: {
            handleDatePickerChange(value) {
                var options = {
                    range: [],
                    selectedDate: null,
                    groupBy: this.selectedStatsOptions.groupBy,
                    selectedDatePickerType: this.selectedStatsOptions.selectedDatePickerType
                };
                var date = new Date(value);
                options.selectedDate = date;
                if (this.selectedStatsOptions.groupBy === 'year') {
                    options.range = [new Date(date.getFullYear(), 0, 1), new Date(date.getFullYear(), 11, 31)];
                } else if (this.selectedStatsOptions.groupBy === 'month') {
                    options.range = [new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth() + 1, 0)];
                } else {
                    options.range = [new Date(date.getFullYear(), date.getMonth(), date.getDate()), new Date(date.getFullYear(), date.getMonth(), date.getDate())];
                }
                this.selectedStatsOptions = options;
            },
            handleChartClick(event) {
                // This method works from the index of the click event to generate the periods.
                var options = {
                    range: [],
                    selectedDate: null,
                    groupBy: null,
                    selectedDatePickerType: null
                };
                var date = new Date(this.selectedStatsOptions.range[0]);
                if (this.selectedStatsOptions.groupBy === 'year') {
                    this.isDateAlreadySet = true;
                    options.range = [new Date(date.getFullYear(), event.dataIndex, 1), new Date(date.getFullYear(), event.dataIndex + 1, 0)];
                    options.groupBy = 'month';
                    options.selectedDate =  new Date(date.getFullYear(), event.dataIndex, 1);
                    options.selectedDatePickerType = 'month';
                    this.selectedStatsOptions = options;
                } else if (this.selectedStatsOptions.groupBy === 'month') {
                    this.isDateAlreadySet = true;
                    options.range = [new Date(date.getFullYear(), date.getMonth(), event.dataIndex + 1), new Date(date.getFullYear(), date.getMonth(), event.dataIndex + 1)];
                    options.groupBy = 'day';
                    options.selectedDate = new Date(date.getFullYear(), date.getMonth(), event.dataIndex + 1);
                    options.selectedDatePickerType = 'date';
                    this.selectedStatsOptions = options;
                }
            },
            disablePreviousDate(ts) {
                return ts > new Date(currentDate.getFullYear(), currentDate.getMonth(),  currentDate.getDate());
            },
            findTenantDeviceKwh() {
                StatisticalService.findElectricityByTenantIdDeviceKwh(this.id, dayjs(this.selectedStatsOptions.range[0]).format('YYYY-MM-DD'), dayjs(this.selectedStatsOptions.range[1]).format('YYYY-MM-DD'), this.selectedStatsOptions.groupBy).then(response => {
                    
                    var result = response.data;
                    var series = [];
                    var labels = [];
                    var isLabelsSet = false;
                    var colourIterator = 0;
                    this.stats.totalDevices = Object.keys(result.data).length;
                    var length = Object.keys(result.data).length - 1;

                    Object.entries(result.data).forEach(
                        ([key, value], index) => {

                            var deviceData = {
                                name: key,
                                data: [],
                                type: 'bar',
                                yAxisIndex: 0,
                                showBackground: false,
                                smooth: false,
                                stack: 'total',
                                itemStyle: {
                                    color: chartColors[colourIterator],
                                    borderRadius: index == length ? [0, 0, 0, 0] : [0, 0, 0, 0],
                                    opacity: 0.8
                                },
                                animationDelay: function (idx) {
                                    return idx * 10 + 100;
                                }
                            };

                            value.forEach((item) => {
                                if (!isLabelsSet) {
                                    labels.push(this.formatDate(item.date, this.selectedStatsOptions.groupBy));
                                }

                                // Ensure that if a value is 0 it is pushed to the data set as null.
                                if (item.value !== 0) {
                                    deviceData.data.push(item.value);
                                } else {
                                    deviceData.data.push(null);
                                }
                            })
                            isLabelsSet = true;
                            colourIterator = colourIterator + 1;
                            series.push(deviceData);
                        }
                    );

                    this.kwhChartData.xAxis.data = labels;
                    this.kwhChartData.series = series;
                }).catch(error => {
                    console.log(error);
                });
            },
            findTenantDeviceKva() {
                StatisticalService.findElectricityByTenantIdDeviceKva(this.id,dayjs(this.selectedStatsOptions.range[0]).format('YYYY-MM-DD'), dayjs(this.selectedStatsOptions.range[1]).format('YYYY-MM-DD'), this.selectedStatsOptions.groupBy).then(response => {
                    var result = response.data;
                    var series = [];
                    var labels = [];
                    var isLabelsSet = false;
                    var colourIterator = 0;

                    Object.entries(result.data).forEach(
                        ([key, value]) => {

                            var deviceData = {
                                name: key,
                                data: [],
                                type: 'line',
                                yAxisIndex: 0,
                                smooth: false,
                                itemStyle: {
                                    color: chartColors[colourIterator],
                                    opacity: 0.8
                                },
                                animationDelay: function (idx) {
                                    return idx * 10 + 100;
                                }
                            };

                            value.forEach((item) => {
                                if (!isLabelsSet) {
                                    labels.push(this.formatDate(item.date, this.selectedStatsOptions.groupBy));
                                }
                                // Ensure that if a value is 0 it is pushed to the data set as null.
                                if (item.value !== 0) {
                                    deviceData.data.push(item.value);
                                } else {
                                    deviceData.data.push(null);
                                }
                            })
                            isLabelsSet = true;
                            colourIterator = colourIterator + 1;
                            series.push(deviceData);
                        }
                    );

                    this.kvaChartData.xAxis.data = labels;
                    this.kvaChartData.series = series;
                }).catch(error => {
                    console.log(error);
                });
            },
            forceRerender() {
                // Removing my-component from the DOM
                this.renderComponent = false;

                this.$nextTick(() => {
                    // Adding the component back in
                    this.renderComponent = true;
                });
            },
            formatDate(date, period) {
                if (period === 'year') {
                    return dayjs(date).format('MMM YYYY');
                } else if (period === 'month') {
                    return dayjs(date).format('D MMM');
                } else if (period === 'day') {
                    return dayjs(date).format('HH:mm');
                }
            },
            handleGroupByChange(value) {
                var options = {
                    range: [],
                    selectedDate: null,
                    groupBy: null,
                    selectedDatePickerType: null
                };
                if (!this.isDateAlreadySet) {
                    if (value === 'year') {
                        options.range = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 11, 31)];
                        options.groupBy = value;
                        options.selectedDatePickerType = 'year';
                        options.selectedDate = new Date(currentDate.getFullYear(), 0, 1);
                    } else if (value === 'month') {
                        options.range = [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)];
                        options.groupBy = value;
                        options.selectedDatePickerType = 'month';
                        options.selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                    } else {
                        options.range = [new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()), new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())];
                        options.groupBy = value;
                        options.selectedDatePickerType = 'date';
                        options.selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                    }
                    this.selectedStatsOptions = options;
                }
                this.isDateAlreadySet = false;
            },
        },
    }
</script>

<style lang="scss" scoped>

#dropdown-list {
    width: 150px;
}

.chart-header-section {
    margin: -15px -10px 10px -10px;
    height: 75px;
    background-color: #F2FCFF;
    z-index: 100;
}

.n-date-picker {
    width: 150px;
}

.actions-section {
    float: right;
}

</style>