<template>
  <div
    v-if="active"
    class="icon-container"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      class="h-5 w-5"
      viewBox="0 0 20 20"
      fill="#1AD620"
    >
      <path
        fill-rule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clip-rule="evenodd"
      />
    </svg>
  </div>
  <div v-else>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      class="h-5 w-5"
      viewBox="0 0 20 20"
      fill="#FF0000"
    >
      <path
        fill-rule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
        clip-rule="evenodd"
      />
    </svg>
  </div>
</template>

<script>
export default {
    name: 'CheckIcon',
    props: {
        active: {
          type: Boolean,
          default: false
        }
    },
}
</script>

<style lang="scss" scoped>
.svg-container {
    display: inline-flex;
}

</style>