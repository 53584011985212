<template>
  <div id="site-manager-view">
    <n-scrollbar>
      <div
        class="card"
        style="height: calc(100% - 105px)"
      >
        <n-space
          vertical
          size="large"
        >
          <n-layout has-sider>
            <n-layout-sider
              collapse-mode="width"
              :collapsed-width="64"
              :width="300"
              bordered
            >
              <div id="search-bar">
                <n-input-group>
                  <n-button
                    strong
                    type="primary"
                  >
                    <template #icon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    </template>
                  </n-button>
                  <n-input
                    v-model:value="pattern"
                    size="medium"
                    clearable
                    placeholder="Search"
                  />
                </n-input-group>
              </div>
              <n-tree
                v-if="tenants"
                class="device-tenant-tree text-medium"
                block-line
                :data="tenants"
                :checked-keys="checkedKeys"
                :check-strategy="checkStrategy"
                :allow-checking-not-loaded="cascade"
                :cascade="cascade"
                :on-load="handleLoad"
                :node-props="nodeProps"
                :pattern="pattern"
                :render-switcher-icon="renderSwitcherIcon"
                :show-irrelevant-nodes="showIrrelevantNodes"
                @drop="handleDrop"
                @update:checked-keys="handleCheckedKeysChange"
                @update:expanded-keys="handleExpandedKeysChange"
                @update:selected-keys="handle"
              />
            </n-layout-sider>
            <n-layout-content>
              <n-scrollbar>
                <div v-if="isTenantView">
                  <div class="tenant-details-heading">
                    <span>
                      <div class="text-small inline-block">
                        <DeviceTypeIcon
                          type="Tenant"
                          :height="25"
                          :width="25"
                        />
                        <span class="bold text-huge">{{ selectedItem.name }}</span><br>
                        <span class="bold">Description: </span> {{ selectedItem.description }}
                      </div>
                    </span>
                  </div>
                  <div class="header-options">
                    <n-tabs type="segment">
                      <n-tab-pane
                        name="electricity-overview"
                        tab="Electricity Overview"
                      >
                        <TenantElectricityChart
                          :id="selectedItem.id"
                        />
                      </n-tab-pane>
                      <n-tab-pane
                        name="water-overview"
                        tab="Water Overview"
                      >
                        <TenantWaterChart
                          :id="selectedItem.id"
                        />
                      </n-tab-pane>
                    </n-tabs>
                  </div>
                </div>
                <!-- If the device selected is the Electricity Meter -->
                <div v-else-if="!isTenantView && selectedItem.device_type.localeCompare('electricity', undefined, {'sensitivity': 'base'}) === 0">
                  <div class="tenant-details-heading">
                    <span>
                      <div class="text-small inline-block">
                        <DeviceTypeIcon
                          type="Electricity"
                          :height="25"
                          :width="25"
                        />
                        <span class="bold text-huge">{{ selectedItem.name }}</span>&nbsp;<span
                          class="text-small"
                          :class="selectedItem.is_online ? 'text-active' : 'text-error'"
                        >({{ selectedItem.is_online ? 'online' : 'offline' }})</span><br>
                        <span class="bold">Serial: </span>{{ selectedItem.serial_number }}<br>
                        <span class="bold">Device Type: </span>{{ selectedItem.device_type }}{{ selectedItem.is_three_phase ? ' 3 Phase' : ' 1 Phase' }}<br>
                      </div>
                    </span>
                  </div>
                  <div class="header-options">
                    <n-tabs type="segment">
                      <n-tab-pane
                        name="overview"
                        tab="Overview"
                      >
                        <DeviceElectricityChart
                          :id="selectedItem.id"
                        />
                      </n-tab-pane>
                    </n-tabs>
                  </div>
                </div>
                <!-- If the device selected is the Water Meter -->
                <div v-else-if="!isTenantView && selectedItem.device_type.localeCompare('water', undefined, {'sensitivity': 'base'}) === 0">
                  <div class="tenant-details-heading">
                    <span>
                      <div class="text-small inline-block">
                        <DeviceTypeIcon
                          type="Water"
                          :height="25"
                          :width="25"
                        />
                        <span class="bold text-huge">{{ selectedItem.name }}</span>&nbsp;<span
                          class="text-small"
                          :class="selectedItem.is_online ? 'text-active' : 'text-error'"
                        >({{ selectedItem.is_online ? 'online' : 'offline' }})</span><br>
                        <span class="bold">Serial: </span>{{ selectedItem.serial_number }}<br>
                        <span class="bold">Device Type: </span>{{ selectedItem.device_type }}<br>
                      </div>
                    </span>
                  </div>
                  <div class="header-options">
                    <n-tabs type="segment">
                      <n-tab-pane
                        name="overview"
                        tab="Overview"
                      >
                        <DeviceWaterChart
                          :id="selectedItem.id"
                        />
                      </n-tab-pane>
                    </n-tabs>
                  </div>
                </div>
              </n-scrollbar>
            </n-layout-content>
          </n-layout>
        </n-space>
      </div>
    </n-scrollbar>
  </div>
</template>

<script>
import { ref, defineComponent, h } from "vue";
import { mapState } from 'vuex';
import BaseMixin from '@/mixins/BaseMixin.js'
import DeviceElectricityChart from '@/components/shared/charts/DeviceElectricityChart'
import DeviceWaterChart from '@/components/shared/charts/DeviceWaterChart'
import TenantElectricityChart from '@/components/shared/charts/TenantElectricityChart'
import TenantWaterChart from '@/components/shared/charts/TenantWaterChart'
import CheckIcon from "@/components/shared/icons/CheckIcon.vue";
import DeviceTypeIcon from "@/components/shared/icons/DeviceTypeIcon.vue"
import DeviceService from '@/services/DeviceService'
import TenantService from '@/services/TenantService'
import Icon from '@/components/shared/Icon'

import { NIcon, NButton } from "naive-ui";

export default defineComponent({
  name: 'SiteManager',
  components: {
    DeviceElectricityChart,
    DeviceWaterChart,
    TenantElectricityChart,
    TenantWaterChart,
    DeviceTypeIcon
},
  mixins: [BaseMixin],
  data:() => ({
        checkStrategy: ref("all"),
        cascade: ref(true),
        expandedKeys: ref([]),
        checkedKeys: ref([]),
        internalData: ref([]),
      pattern: ref(''),
      showIrrelevantNodes: ref(false),
      initialLoad: ref(true),
      isTenantView: ref(true),
      searchText: ref(null),
      tenants: ref([]),
      selectedItem: ref({}),
  }),
  computed: mapState(['selectedOwningOrg']),
  watch: {
      selectedOwningOrg: {
          handler: function() {
            this.isTenantView = true;
            this.findTenants();
          }
      },
  },
  mounted: function() {
  },
  created: function() {
    this.findTenants();
  },
  methods: {
    handleSearchInput(value) {
      console.log(value)
      this.searchText = value;
    },
    renderSwitcherIcon() {
      return h(NIcon, {}, { default: () => h(Icon, {name: 'chevron-right'}) });
    },
    search(value) {
      this.searchText = value;
    },
    setSelectedItem(item) {
      this.selectedItem = item;
    },
    async findDeviceChildren(id) {
      var devices = await DeviceService.findChildren(id);
      devices.data.forEach(item => {
        item.label = item.name;
        item.key = item.id + 'd';
        item.isLeaf = item.has_children ? false : true;
        item.prefix = () => h(NIcon, {}, { default: () => h(CheckIcon, {active: item.is_online})});
        item.suffix = () => h(NIcon, {}, { default: () => h(DeviceTypeIcon, {type: item.device_type }) });
      });
      return devices.data;
    },
    async findTenantDevices(id) {
      var devices = await TenantService.findDevices(id);
      devices.data.forEach(item => {
            item.label = item.name;
            item.key = item.id;
            item.isLeaf = item.has_children ? false : true;
            item.prefix = () => h(NIcon, {}, { default: () => h(CheckIcon, {active: item.is_online})});
            item.suffix = () => h(NIcon, {}, { default: () => h(DeviceTypeIcon, {type: item.device_type })});
      });
      return devices.data;
    },
    findTenants() {
      TenantService.find(this.$store.state.selectedOwningOrg.id, this.searchText).then(response => {
        console.log('tenants',response.data)
        this.tenants = response.data;
        const internal = this.tenants;
        internal.forEach(item => {
          item.label = item.name;
          item.key = item.id + 't';
          item.isLeaf = false;
          item.suffix = () => h(NIcon, {}, { default: () => h(Icon , {name: 'office-building'}) });
        });
        this.internalData = internal;
        if (response.data && response.data.length) {
          this.selectedItem = response.data[0]
        }
      }).catch(error => {
        console.log(error);
      });
    },
    handle(key, object) {
      if (object[0].classification === 'device') {
        this.isTenantView = false;
      } else {
        this.isTenantView = true;
      }
      this.setSelectedItem(object[0]);
    },
    handleExpandedKeysChange(expandedKeys) {
        this.expandedKeys.push(expandedKeys);
    },
    handleCheckedKeysChange(checkedKeys) {
        this.checkedKeys.push(checkedKeys);
    },
    // handleDrop({ node, dragNode, dropPosition }) {
    //   const [dragNodeSiblings, dragNodeIndex] = this.findSiblingsAndIndex(dragNode, this.internalData.value);
    //   if (dragNodeSiblings === null || dragNodeIndex === null)
    //     return;
    //   dragNodeSiblings.splice(dragNodeIndex, 1);
    //   if (dropPosition === "inside") {
    //     if (node.children) {
    //       node.children.unshift(dragNode);
    //     } else {
    //       node.children = [dragNode];
    //     }
    //   } else if (dropPosition === "before") {
    //     const [nodeSiblings, nodeIndex] = this.findSiblingsAndIndex(node, this.internalData.value);
    //     if (nodeSiblings === null || nodeIndex === null)
    //       return;
    //     nodeSiblings.splice(nodeIndex, 0, dragNode);
    //   } else if (dropPosition === "after") {
    //     const [nodeSiblings, nodeIndex] = this.findSiblingsAndIndex(node, this.internalData.value);
    //     if (nodeSiblings === null || nodeIndex === null)
    //       return; 
    //     nodeSiblings.splice(nodeIndex + 1, 0, dragNode);
    //   }
    //   this.internalData.value = Array.from(this.internalData.value);
    // },
    async handleLoad(node) {
      if (this.initialLoad) {
        // console.log('this data', this.internalData)
        // console.log('node', node)
        if (node.classification === 'tenant') {
           return new Promise((resolve) => {
            this.findTenantDevices(node.id).then((result) => {
              node.children = result;
              resolve();
            });
           });
        } else if (node.classification === 'device' && node.has_children) {
           return new Promise((resolve) => {
            this.findDeviceChildren(node.id).then((result) => {
              node.children = result;
              resolve();
            });
           });
        }
      }
      this.initialLoad = false;
    },
    findSiblingsAndIndex(node, nodes) {
        if (!nodes)
          return [null, null];
        for (let i = 0; i < nodes.length; ++i) {
            const siblingNode = nodes[i];
            if (siblingNode.key === node.key)
              return [nodes, i];
            const [siblings, index] = this.findSiblingsAndIndex(node, siblingNode.children);
            if (siblings && index !== null)
              return [siblings, index];
          }
          return [null, null];
    },
    nodeProps: ({ option }) => {
      return {
        onClick() {
          //  console.log('option',option);
        },
      };
    }
  },
});
</script>

<style lang="scss">

  #site-manager-view {
    #search-bar .n-base-clear {
      margin-top: -8px;
    }

    .n-layout {
      .n-layout-scroll-container {
        overflow-y: hidden;
      } 
    }
    .n-tabs .n-tabs-nav {
      padding-left: 5px;
    }
  }

</style>

<style lang="scss" scoped>

  .tenant-device-svg {
    margin-bottom: -2px;
    padding-right: 5px;
    height: 25px;
    width: 25px;
  }
  .device-tenant-tree {
    padding-top: 10px;
  }

  .tenant-details-heading {
    padding: 10px 20px;
    width: 100%;
    height: 80px;
  }

  #search-bar {
    .n-input-group {
      width: calc(100% - 10px);
      padding: 0px 0px;
      .input {
        margin-top: 8px;
      }
    }

    .n-button {
      width: 50px;
      height: 44px;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0px 3px 6px #00000029;
    }

    .n-input .n-input__suffix, .n-input .n-input__prefix {
      padding-bottom: 10px;
    }
  }

</style>
