<template>
  <div id="button-with-icon-and-text">
    <n-button
      strong
      secondary
      class="button-default"
      :size="size"
      :loading="loading"
      :type="type"
      :disabled="disabled"
      :style="width == null ? '' : `width: ${width}px;`"
    >
      <template #icon>
        <slot />
      </template>
      {{ text }}
    </n-button>
  </div>
</template>

<script>
import { defineComponent } from "vue";


export default defineComponent({
  name: 'ButtonWithIconAndText',
  props: {
    type: {
        type: String,
        default: 'primary',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    text: {
        type: String,
        default: null
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small'
    },
    width: {
      type: String,
      default: null
    }
  },
})
</script>

<style lang="scss">

  #button-with-icon-and-text {
    .button-default {
      color: #fff !important;
      background-color: #33A4D3 !important;
    }

    .button-inactive {
      color: #000;
      .svg {
        color: #177799;
      }
    }
  }

</style>

<style lang="scss" scoped>
  .n-button {
    padding: 19.5px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
  }
</style>