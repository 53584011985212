
<template>
  <div
    v-if="name"
    class="icon-container"
  >
    <img :src="require(`../../assets/icons/${name}.svg`)">
  </div>
</template>

<script>
export default {
    // This component is for all generic svg that does not require color styling. For any icons with colors use the icons in the icons folder for custom styling.
    name: 'Icon',
    props: {
        name: {
            type: String,
            default: null
        },
        state: {
          type: String,
          default: 'active'
        }
    },
}
</script>

<style lang="scss" scoped>
.svg-container {
    display: inline-flex;
}

</style>