<template>
  <div
    v-if="type === 'Electricity'"
    class="icon-container"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5 normal-svg"
      :height="height + 'px'"
      :width="width + 'px'"
      viewBox="0 0 20 20"
      fill="#FFAC26"
    >
      <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
    </svg>
  </div>
  <div
    v-if="type === 'Water'"
    class="icon-container"
  >
    <svg
      class="rotated-svg"
      xmlns="http://www.w3.org/2000/svg"
      transform="rotate(180)"
      :height="height + 'px'"
      :width="width + 'px'"
      viewBox="0 0 20 20"
      fill="#1CA4D3"
    >
      <path d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9z 11a2 2 0 100-4 2 2 0 000" />
    </svg>
  </div>
  <div
    v-if="type === 'Tenant'"
    class="icon-container"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      class="normal-svg"
      :height="height + 'px'"
      :width="width + 'px'"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
      />
    </svg>
  </div>
</template>

<script>
export default {
    name: 'DeviceTypeIcon',
    props: {
        type: {
          type: String,
          default: 'Electricity'
        },
        height: {
          type: Number,
          default: 10
        },
        width: {
          type: Number,
          default: 10
        }
    },
}
</script>

<style lang="scss" scoped>
  
  .normal-svg {
    margin-bottom: -2px;
    padding-right: 5px;
  }

  .rotated-svg {
    margin-bottom: -2px;
    padding-left: 5px;
  }

  .icon-container {
    display: inline-block;
  }

</style>