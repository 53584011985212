<template>
  <n-space
    id="device-chart"
    vertical
  >
    <div class="card">
      <div class="card chart-header-section">
        <div class="card-heading">
          <div class="inline-block">
            <span class="bold text-large">{{ selectedStatsOptions.groupBy === 'day'? 'Daily' :selectedStatsOptions.groupBy.charAt(0).toUpperCase() + selectedStatsOptions.groupBy.slice(1) + 'ly' }} Electricity Consumption</span><br>
            <span class="text-small">
              <span class="bold">Status: </span> {{ stats.active }}  
              <span class="bold">Updated: </span> {{ stats.lastUpdated }}
            </span>
          </div>
          <div class="actions-section">
            <n-space justify="end">
              <ButtonWithIconAndText
                v-if="selectedStatsOptions.dimension == 'general'"
                :text="'Download Report'"
                :loading="loadingReport"
                @click="findDeviceReport"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  />
                </svg>
              </ButtonWithIconAndText>
              <DropdownList
                v-model:value="selectedStatsOptions.dimension"
                :placeholder="'Data Type'"
                :options="dimension"
                @valueChanged="handledimensionChange"
              />
              <DropdownList
                v-model:value="selectedStatsOptions.groupBy"
                :placeholder="'Group By'"
                :options="groupBy"
                @valueChanged="handleGroupByChange"
              />
              <n-date-picker
                v-if="renderComponent"
                v-model:value="selectedStatsOptions.selectedDate"
                :actions="selectedStatsOptions.selectedDatePickerType === 'date' ? [] : ['confirm']"
                :is-date-disabled="disablePreviousDate"
                :type="selectedStatsOptions.selectedDatePickerType"
                size="large"
                @update:value="handleDatePickerChange"
              />
            </n-space>
          </div>
        </div>
      </div>
      <n-scrollbar :style="`max-height: ${height}px;`">
        <v-chart
          style="height: 540px;"
          :option="chartData"
          :update-options="updateOptions"
          autoresize
          @click="handleChartClick"
        />
      </n-scrollbar>
    </div>
  </n-space>
</template>

<script>
    import { ref, defineComponent } from "vue";
    import { use } from "echarts/core";
    import { CanvasRenderer } from "echarts/renderers";
    import { BarChart, LineChart } from "echarts/charts";
    import { TitleComponent, TooltipComponent, LegendComponent, GridComponent } from "echarts/components";
    import VChart, { THEME_KEY } from "vue-echarts";
    import * as dayjs from 'dayjs'
    import BaseMixin from '@/mixins/BaseMixin.js'
    import ButtonWithIconAndText from '@/components/shared/ButtonWithIconAndText.vue'
    import DeviceService from '@/services/DeviceService'
    import DropdownList from '@/components/shared/DropdownList.vue'
    import StatisticalService from '@/services/StatisticalService'
    import ReportService from '@/services/ReportService'

    const currentDate = new Date();
    var relativeTime = require('dayjs/plugin/relativeTime')

    use([
        BarChart,
        LineChart,
        CanvasRenderer,
        TitleComponent,
        TooltipComponent,
        LegendComponent,
        GridComponent
    ]);

    export default defineComponent({
        name: 'DeviceElectricityChart',
        components: {
            ButtonWithIconAndText,
            DropdownList,
            VChart,
        },
        mixins: [BaseMixin],
        props: {
            id: {
                type: Number,
                default: 0,
            },
            height: {
                type: Number,
                default: 550,
            }
        },
        data:() => ({
            isDateAlreadySet: ref(false),
            loadingReport: ref(false),
            renderComponent: ref(true),
            selectedStatsOptions: ref({
                range: [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)],
                groupBy: 'month',
                dimension: 'general',
                selectedDate: ref(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
                selectedDatePickerType: ref('month')
            }),
            stats: ref({
                lastUpdated: null
            }),
            updateOptions: {
                notMerge: true
            },
            groupBy: ref([{
                label: 'Year',
                value: 'year'
                },
                {
                label: 'Month',
                value: 'month'
                },
                {
                label: "Day",
                value: 'day'
            }]),
            dimension: ref([{
                label: 'Active/Apparent',
                value: 'general'
                },
                {
                label: "Current",
                value: 'current'
                },
                {
                label: "Frequency",
                value: 'frequency'
                },

                {
                label: "Powerfactor",
                value: 'powerfactor'
                },
                {
                label: 'Voltage',
                value: 'voltage'
                }]),
            chartData: ref({
                grid: {
                    show: false
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    },
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    nameRotate: '90',
                    triggerEvent: true,
                    axisLabel: {
                        interval: 0,
                        rotate: 90,
                        formatter: function (value) {
                            return value;
                        }
                    },
                    axisTick: {
                        alignWithLabel: true
                    },
                },
                series:[],
                yAxis: [],
                animationEasing: 'elasticOut',
                animationDelayUpdate: function (idx) {
                    return idx * 5;
                }
            }),
        }),
        watch: {
            id: {
                immediate: true,
                handler: function(newVal, oldVal) {
                    if (newVal != 0) {
                        this.findDeviceById();
                        var options = {
                            range: [],
                            selectedDate: null,
                            groupBy: null,
                            dimension: null,
                            selectedDatePickerType: null
                        };
                        var date = new Date();
                        options.range = [new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth() + 1, 0)];
                        options.groupBy = 'month';
                        options.selectedDatePickerType = 'month';
                        options.selectedDate = date;
                        options.dimension = this.selectedStatsOptions.dimension;
                        this.selectedStatsOptions = options;
                    }
                }
            },
            selectedStatsOptions: {
                deep: true,
                handler: function(value) {
                    this.forceRerender();
                    if (value.dimension === 'general') {
                        this.findDeviceGeneral();
                    } else if (value.dimension === 'voltage') {
                        this.findDeviceVoltage();
                    } else if (value.dimension === 'frequency') {
                        this.findDeviceFrequency();
                    } else if (value.dimension === 'powerfactor') {
                        this.findDevicePowerfactor();
                    } else if (value.dimension === 'current') {
                        this.findDeviceCurrent();
                    }
                }
            },
        },
        methods: {
            handleDatePickerChange(value) {
                var options = {
                    range: [],
                    selectedDate: null,
                    groupBy: this.selectedStatsOptions.groupBy,
                    selectedDatePickerType: this.selectedStatsOptions.selectedDatePickerType,
                    dimension: this.selectedStatsOptions.dimension
                };
                var date = new Date(value);
                options.selectedDate = date;
                if (this.selectedStatsOptions.groupBy === 'year') {
                    options.range = [new Date(date.getFullYear(), 0, 1), new Date(date.getFullYear(), 11, 31)];
                } else if (this.selectedStatsOptions.groupBy === 'month') {
                    options.range = [new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth() + 1, 0)];
                } else {
                    options.range = [new Date(date.getFullYear(), date.getMonth(), date.getDate()), new Date(date.getFullYear(), date.getMonth(), date.getDate())];
                }
                this.selectedStatsOptions = options;
            },
            handleChartClick(event) {
                // This method works from the index of the click event to generate the periods.
                var options = {
                    range: [],
                    selectedDate: null,
                    groupBy: null,
                    dimension: this.selectedStatsOptions.dimension,
                    selectedDatePickerType: null
                };
                var date = new Date(this.selectedStatsOptions.range[0]);
                if (this.selectedStatsOptions.groupBy === 'year') {
                    this.isDateAlreadySet = true;
                    options.range = [new Date(date.getFullYear(), event.dataIndex, 1), new Date(date.getFullYear(), event.dataIndex + 1, 0)];
                    options.groupBy = 'month';
                    options.selectedDate =  new Date(date.getFullYear(), event.dataIndex, 1);
                    options.selectedDatePickerType = 'month';
                    this.selectedStatsOptions = options;
                } else if (this.selectedStatsOptions.groupBy === 'month') {
                    this.isDateAlreadySet = true;
                    options.range = [new Date(date.getFullYear(), date.getMonth(), event.dataIndex + 1), new Date(date.getFullYear(), date.getMonth(), event.dataIndex + 1)];
                    options.groupBy = 'day';
                    options.selectedDate = new Date(date.getFullYear(), date.getMonth(), event.dataIndex + 1);
                    options.selectedDatePickerType = 'date';
                    this.selectedStatsOptions = options;
                }
            },
            formatDate(date, period) {
                if (period === 'year') {
                    return dayjs(date).format('MMM YYYY');
                } else if (period === 'month') {
                    return dayjs(date).format('D MMM');
                } else if (period === 'day') {
                    return dayjs(date).format('HH:mm');
                }
            },
            forceRerender() {
                // Removing my-component from the DOM
                this.renderComponent = false;

                this.$nextTick(() => {
                    // Adding the component back in
                    this.renderComponent = true;
                });
            },
            handleGroupByChange(value) {
                var options = {
                    range: [],
                    selectedDate: null,
                    groupBy: null,
                    dimension: this.selectedStatsOptions.dimension,
                    selectedDatePickerType: null
                };
                if (!this.isDateAlreadySet) {
                    if (value === 'year') {
                        options.range = [new Date(currentDate.getFullYear(), 0, 1), new Date(currentDate.getFullYear(), 11, 31)];
                        options.groupBy = value;
                        options.selectedDatePickerType = 'year';
                        options.selectedDate = new Date(currentDate.getFullYear(), 0, 1);
                    } else if (value === 'month') {
                        options.range = [new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)];
                        options.groupBy = value;
                        options.selectedDatePickerType = 'month';
                        options.selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                    } else {
                        options.range = [new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()), new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())];
                        options.groupBy = value;
                        options.selectedDatePickerType = 'date';
                        options.selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                    }
                    this.selectedStatsOptions = options;
                }
                this.isDateAlreadySet = false;
            },
            handledimensionChange(value) {
                var options = {
                    dimension: value,
                    range: this.selectedStatsOptions.range,
                    groupBy: this.selectedStatsOptions.groupBy,
                    selectedDatePickerType: this.selectedStatsOptions.selectedDatePickerType,
                    selectedDate: this.selectedStatsOptions.selectedDate
                };
                this.selectedStatsOptions = options;
            },
            findDeviceReport() {
                this.loadingReport = true
                this.createLoadingMessage('We are preparing your report.');
                ReportService.findElectricityByDeviceId(this.id, dayjs(this.selectedStatsOptions.range[0]).format('YYYY-MM-DD'), dayjs(this.selectedStatsOptions.range[1]).format('YYYY-MM-DD'), this.selectedStatsOptions.groupBy).then(response => {
                    this.parseReportAndDownload(response.data, this.id);
                    this.destroyLoadingMessage();
                    this.loadingReport = false;
                });
            },
            disablePreviousDate(ts) {
                return ts > new Date(currentDate.getFullYear(), currentDate.getMonth(),  currentDate.getDate());
            },
            formatDataForChart(response, colorList, isThreePhase, yAxisSuffix) {

                var result = {
                    labels: [],
                    series: [],
                    yAxis: []
                };
                dayjs.extend(relativeTime)
                this.stats.lastUpdated = dayjs(response.aggregated.last_updated).fromNow();
                var isLabelsSet = false;

                console.log(response)

                Object.entries(response.data).forEach(
                        ([key, value], index) => {

                            var deviceData = {
                                name: isThreePhase ? key.includes('one') ? 'Phase A' : (key.includes('two') ? 'Phase B' : 'Phase C') : key,
                                data: [],
                                type: 'line',
                                yAxisIndex: 0,
                                showBackground: false,
                                smooth: false,
                                itemStyle: {
                                    color: colorList[index],
                                    opacity: 0.8
                                },
                                animationDelay: function (idx) {
                                    return idx * 10 + 100;
                                }
                            };

                            value.forEach((item) => {
                                if (!isLabelsSet) {
                                    result.labels.push(this.formatDate(item.date, this.selectedStatsOptions.groupBy));
                                }

                                // Ensure that if a value is 0 it is pushed to the data set as null.
                                if (item.value !== 0) {
                                    deviceData.data.push(item.value);
                                } else {
                                    deviceData.data.push(null);
                                }
                            });

                            result.yAxis.push({
                                type: 'value',
                                axisLabel: {
                                    formatter: function (value) {
                                        return value + ' ' + yAxisSuffix;
                                    }
                                }
                            });

                            isLabelsSet = true;
                            result.series.push(deviceData);
                        }
                    );
                return result;
            },
            findDeviceById() {
                DeviceService.findById(this.id).then(response => {
                    this.device = response.data;
                });
            },
            findDeviceGeneral() {
                StatisticalService.findElectricityGeneralByDeviceId(this.id, dayjs(this.selectedStatsOptions.range[0]).format('YYYY-MM-DD'), dayjs(this.selectedStatsOptions.range[1]).format('YYYY-MM-DD'), this.selectedStatsOptions.groupBy).then(response => {

                    var result = response.data;
                    var kwh = [];
                    var kva = [];
                    var labels = [];
                    this.chartData.series  = [];

                    dayjs.extend(relativeTime)
                    this.stats.lastUpdated = dayjs(result.aggregated.last_updated).fromNow();

                    result.data.forEach((item) => {
                        labels.push(this.formatDate(item.date, this.selectedStatsOptions.groupBy));
                        // Ensure that if a value is 0 it is pushed to the data set as null.
                        if (item.kwh !== 0) {
                            kwh.push(item.kwh);
                        } else {
                            kwh.push(null);
                        }

                        // Ensure that if a value is 0 it is pushed to the data set as null.
                        if (item.kva !== 0) {
                            kva.push(item.kva);
                        } else {
                            kva.push(null);
                        }
                    });

                    this.chartData.yAxis = [{
                            type: 'value',
                            axisLabel: {
                                formatter: function (value) {
                                    if (value < 1000) {
                                        return value + ' kWh';
                                    } else if (value >= 1000 && value <= 1000000) {
                                        return ( value / 1000 ) + ' MWh';
                                    } else {
                                        return ( value / 1000000 ) + 'Gwh';
                                    }
                                }
                            }
                        },
                        {
                            type: 'value',
                            axisLabel: {
                                formatter: function (value) {
                                    if (value < 1000) {
                                        return value + ' kVA';
                                    } else {
                                        return ( value / 1000 ) + ' MVA';
                                    }
                                }
                            }
                        }
                    ],
                    this.chartData.xAxis.data = labels;
                    this.chartData.series = [{
                            name: 'kWh',
                            data: kwh,
                            type: 'bar',
                            showBackground: false,
                            itemStyle: {
                                borderRadius: [0, 0, 0, 0],
                                color: '#FFAC26',
                                opacity: 0.8
                            },
                            animationDelay: function (idx) {
                                return idx * 10;
                            }
                        },{
                            name: 'kVA',
                            data: kva,
                            type: 'line',
                            yAxisIndex: 1,
                            smooth: false,
                            itemStyle: {
                                color: '#FF0000',
                                opacity: 0.8
                            },
                            animationDelay: function (idx) {
                                return idx * 10 + 100;
                            }
                    }];
                    
                }).catch(error => {
                    console.log(error);
                });
            },

            findDeviceVoltage() {
                StatisticalService.findElectricityVoltageByDeviceId(this.id, dayjs(this.selectedStatsOptions.range[0]).format('YYYY-MM-DD'), dayjs(this.selectedStatsOptions.range[1]).format('YYYY-MM-DD'), this.selectedStatsOptions.groupBy).then(response => {

                    var colorList = [
                            '#FD60FF',
                            '#F7D518',
                            '#1E57FA'
                        ];
                    var result = this.formatDataForChart(response.data, colorList, this.device.is_three_phase, 'V');

                    this.chartData.yAxis = result.yAxis;
                    this.chartData.xAxis.data = result.labels;
                    this.chartData.series = result.series;
                    
                }).catch(error => {
                    console.log(error);
                });
            },
            findDeviceCurrent() {
                StatisticalService.findElectricityCurrentByDeviceId(this.id, dayjs(this.selectedStatsOptions.range[0]).format('YYYY-MM-DD'), dayjs(this.selectedStatsOptions.range[1]).format('YYYY-MM-DD'), this.selectedStatsOptions.groupBy).then(response => {
                    
                    var colorList = [
                            '#FD60FF',
                            '#F7D518',
                            '#1E57FA'
                        ];
                    var result = this.formatDataForChart(response.data, colorList, this.device.is_three_phase, 'A');

                    this.chartData.yAxis = result.yAxis;
                    this.chartData.xAxis.data = result.labels;
                    this.chartData.series = result.series;
                    
                }).catch(error => {
                    console.log(error);
                });
            },
            findDevicePowerfactor() {
                StatisticalService.findElectricityPowerfactorByDeviceId(this.id, dayjs(this.selectedStatsOptions.range[0]).format('YYYY-MM-DD'), dayjs(this.selectedStatsOptions.range[1]).format('YYYY-MM-DD'), this.selectedStatsOptions.groupBy).then(response => {

                    var colorList = [
                            '#FD60FF',
                            '#F7D518',
                            '#1E57FA'
                        ];
                    var result = this.formatDataForChart(response.data, colorList, this.device.is_three_phase, '');

                    this.chartData.yAxis = result.yAxis;
                    this.chartData.xAxis.data = result.labels;
                    this.chartData.series = result.series;
                    
                }).catch(error => {
                    console.log(error);
                });
            },
            findDeviceFrequency() {
                StatisticalService.findElectricityFrequencyByDeviceId(this.id, dayjs(this.selectedStatsOptions.range[0]).format('YYYY-MM-DD'), dayjs(this.selectedStatsOptions.range[1]).format('YYYY-MM-DD'), this.selectedStatsOptions.groupBy).then(response => {

                var colorList = [
                        '#900C3F'
                    ];
                var result = this.formatDataForChart(response.data, colorList, false, 'Hz');

                this.chartData.yAxis = result.yAxis;
                this.chartData.xAxis.data = result.labels;
                this.chartData.series = result.series;

                }).catch(error => {
                    console.log(error);
                });
            }
        },
    })
</script>

<style lang="scss" scoped>

#dropdown-list {
    width: 150px;
}

.chart-header-section {
    margin: -15px -10px 10px -10px;
    height: 75px;
    background-color: #F2FCFF;
    z-index: 100;

    .n-layout {
        background-color: inherit;
    }
}

.n-date-picker {
    width: 150px;
}

.actions-section {
    float: right;
}

</style>