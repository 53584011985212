import axios from './BaseService.js'
const baseURL = process.env.VUE_APP_API_URL + '/management';

export default {
  async find(owningOrgId, search) {
    var params = {
      owningOrgId: owningOrgId,
      search: search,
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get('/tenants', { params });
  },
  async findById(id) {
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/tenants/${id}`);
  },
  async findDevices(id) {
    axios.defaults.baseURL = baseURL;
    return await axios.get(`tenant/${id}/devices`);
  }
};