import axios from './BaseService.js'
const baseURL = process.env.VUE_APP_API_URL + '/management';

export default {
  async find(id, search) {
    var params = {
      tenantId: id,
      search: search,
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get('/devices', { params });
  },
  async findById(id) {
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/devices/${id}`);
  },
  async findChildren(id) {
    axios.defaults.baseURL = baseURL;
    return await axios.get(`device/${id}/children`);
  },
  async deleteById(id) {
    axios.defaults.baseURL = baseURL;
    return await axios.delete(`/devices/${id}`);
  },
};